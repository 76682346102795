import { createContext, useContext, createEffect } from 'solid-js';
import { cookieStorage, makePersisted } from '@solid-primitives/storage';
import { createStore, produce } from 'solid-js/store';
import { useSearchParams } from '@solidjs/router';
import type { ParentProps } from 'solid-js';

type StoreData = Record<string, { value: string; expires: number }>;
type Store = ReturnType<typeof createStore<StoreData>>;
const Persistence = createContext<Store>();

export function PersistenceProvider(props: ParentProps) {
	const [store, setStore] = makePersisted(createStore<StoreData>({}), {
		name: 'tgp',
		storage: cookieStorage.withOptions({
			expires: new Date(+new Date() + 1000 * 60 * 60 * 24 * 30),
			sameSite: 'Strict',
			secure: true,
		}),
		serialize(data) {
			return btoa(JSON.stringify(data));
		},
		deserialize(data) {
			let parsed: StoreData = {};
			try {
				parsed = JSON.parse(atob(data));
			} catch {
				// no-op
			}

			return Object.entries(parsed).reduce((memo, [key, val]) => {
				if (typeof val === 'string') {
					memo[key] = { value: val, expires: Date.now() + 1000 * 60 * 60 * 24 };
					return memo;
				}
				if (val.expires > Date.now()) {
					memo[key] = val;
				}
				return memo;
			}, {} as StoreData);
		},
	});

	return <Persistence.Provider value={[store, setStore]}>{props.children}</Persistence.Provider>;
}

export function usePersisted() {
	const ctx = useContext(Persistence);
	if (!ctx) {
		throw new Error('Persistence store not initialized');
	}

	return ctx;
}

export function createPersisted(
	keys: Record<string, number>,
	nonParams?: Record<string, { value: string; expires: number }>,
) {
	const [search] = useSearchParams();
	const [store, setStore] = usePersisted();

	if (nonParams) {
		setStore(
			produce((s) => {
				for (const [key, val] of Object.entries(nonParams ?? {})) {
					s[key] = val;
				}
			}),
		);
	}

	createEffect(() => {
		setStore(
			produce((s) => {
				if (!keys) {
					return;
				}
				for (const key in keys) {
					const value = search[key];
					const expires = keys[key] ?? Date.now() + 1000 * 60 * 60 * 24;
					if (value) {
						if (Array.isArray(value)) {
							if (value.length) {
								s[key] = { value: value[0]!, expires };
							}
						} else {
							s[key] = { value, expires };
						}
					}
				}
			}),
		);
	});

	return [store, setStore];
}
